const reviews = [
    {
        id: 0,
        company: 'Deffo not Fifab',
        review: 'Summers is a fantastic company who move all of our freight, Gordan can be a bit testy at times, but we know he means well'
    }, 
    {
        id: 1,
        company: 'Deffo not Fourstones',
        review: 'Summers to everything for us, they move all of our freight and bring all of our product in, we couldn\'t exist without them!'
    },
    {
        id: 2,
        company: 'Deffo not Donaldson\'s',
        review: 'Ach man! Summers? Amazing people! They handle all of our work with class and professionality! All of out site work and timber deliveries!'
    }
]

export default reviews;